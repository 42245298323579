import { Default } from './themes/default'
// Staging Services
import { BarrowsPurdy } from './themes/1-BarrowsPurdy'
import { Lollipops } from './themes/979-Lollipops'
import { Sweethearts } from './themes/980-Sweethearts'

// Production Services
import { WarringahMall } from './themes/1129-WarringahMall'

export const themes = {
  default: Default,

  //#region Staging Services
  '1': BarrowsPurdy,
  // '979': Lollipops,
  // '980': Sweethearts,
  //#endregion

  //#region Production Services
  // '1129': WarringahMall,
  //#endregion
}
