import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

export interface IAlert {
  open: boolean
  type: 'success' | 'warning' | 'error' | 'info'
  message: string
}

interface IAlertSnackbarProps extends IAlert {
  onClose: () => void
}

const AlertSnackbar = (props: IAlertSnackbarProps) => {
  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={5000}
      onClose={props.onClose}
      sx={{ width: { sm: '100%' } }}
    >
      <Alert
        variant='filled'
        severity={props.type}
        onClose={props.onClose}
        sx={{ marginX: 1, width: '100%' }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  )
}

export default AlertSnackbar
