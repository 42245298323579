import { useEffect, useMemo, useState } from 'react'
import Head from 'next/head'
import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import ApiProviderContext from 'common/context/api-provider-context'
import AlertSnackbar, { IAlert } from 'common/components/alert-snackbar'
import EventEmitter from 'common/utils/event-emitter'
import { AdminContextProvider } from 'modules/pin/context/admin-pin-context'
import { SessionFilterContextProvider } from 'modules/record/context/session-filter-context'
import { themes } from 'common/utils/themes'

function Kiosk({ Component, pageProps, router }: AppProps) {
  const { query } = router
  const { srvid } = query
  const [alert, setAlert] = useState<IAlert>({} as IAlert)

  useEffect(() => {
    EventEmitter.addListener('onAlert', (alt: IAlert) =>
      setAlert({ ...alt, open: true })
    )
    return () => EventEmitter.removeAllListeners('onAlert')
  }, [])

  const theme = useMemo(() => {
    const srvId = srvid as string
    if (srvId in themes) {
      return themes[srvId as keyof typeof themes]
    }
    return themes.default
  }, [srvid])

  return (
    <>
      <Head>
        <title>Kiosk - SmartCentral</title>
        <meta name='kiosk' content='kiosk' />
        <link rel='icon' type='images/png' href='/favicon.png' />
      </Head>

      <ThemeProvider theme={theme}>
        <CssBaseline />

        <AlertSnackbar
          open={alert.open}
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert({ ...alert, open: false, message: '' })}
        />

        <SessionProvider session={pageProps.session} refetchInterval={1800}>
          <ApiProviderContext>
            <SessionFilterContextProvider>
              <AdminContextProvider>
                <Component {...pageProps} />
              </AdminContextProvider>
            </SessionFilterContextProvider>
          </ApiProviderContext>
        </SessionProvider>
      </ThemeProvider>
    </>
  )
}

export default Kiosk
