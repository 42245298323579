import { createContext, useState, useContext, ReactNode } from 'react'
import { PinStaffResponse } from 'generated/restapi/models/pin-staff-response'

export interface IAdminPinStatus {
  pin?: string
  response?: PinStaffResponse
}

interface IAdminContextProps {
  pinStatus?: IAdminPinStatus
  setPinStatus: (val?: IAdminPinStatus) => void
}

interface IAdminContextProviderProps {
  children: ReactNode
}

const adminContext = createContext({} as IAdminContextProps)

export const AdminContextProvider = (props: IAdminContextProviderProps) => {
  const [pinStatus, setPinStatus] = useState<IAdminPinStatus>()

  return (
    <adminContext.Provider value={{ pinStatus, setPinStatus }}>
      {props.children}
    </adminContext.Provider>
  )
}

export const useAdminContext = () => useContext(adminContext)
