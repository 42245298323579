import { EventEmitter } from 'fbemitter'

export const EVENT_ALERT = 'onAlert'

let _emitter: EventEmitter | null
const getEmitter = () => {
  if (!_emitter) {
    _emitter = new EventEmitter()
  }
  return _emitter
}

export const emitSuccess = (message: string) => {
  _emitter?.emit(EVENT_ALERT, { type: 'success', message: message })
}

export const emitError = (message: string) => {
  _emitter?.emit(EVENT_ALERT, { type: 'error', message: message })
}

export default getEmitter()
