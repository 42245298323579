import {
  createContext,
  useState,
  useContext,
  ReactNode,
  SetStateAction,
  Dispatch,
} from 'react'
import { ISessionFilters } from 'modules/record/components/session-filters'

interface ISessionFilterContextProps {
  sessionFilters: ISessionFilters
  setSessionFilters: Dispatch<SetStateAction<ISessionFilters>>
}

interface ISessionFilterContextProviderProps {
  children: ReactNode
}

const sessionFilterContext = createContext({} as ISessionFilterContextProps)

export const SessionFilterContextProvider = (
  props: ISessionFilterContextProviderProps
) => {
  const [sessionFilters, setSessionFilters] = useState<ISessionFilters>({
    room: [],
    attendance: [],
    sessionCode: [],
    school: [],
    presence: [],
  })

  return (
    <sessionFilterContext.Provider
      value={{ sessionFilters, setSessionFilters }}
    >
      {props.children}
    </sessionFilterContext.Provider>
  )
}

export const useSessionFilterContext = () => useContext(sessionFilterContext)
