import { createTheme } from '@mui/material/styles'

export const BarrowsPurdy = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ede7f6',
      dark: '#9575cd',
      contrastText: '#330f5c',
    },
    secondary: {
      main: '#7dff8a',
    },
    background: {
      default: '#330f5c',
      paper: '#4a2278',
    },
    text: {
      primary: '#e0e0e0',
      secondary: '#b388ff',
      disabled: '#757575',
    },
    success: {
      main: '#8fff93',
    },
    error: {
      main: '#d32f2f',
    },
  },
  typography: {
    fontFamily: `'Poppins'`,
    body1: {
      fontFamily: `'Roboto'`,
    },
    button: {
      fontFamily: `'Roboto'`,
    },
    caption: {
      fontFamily: `'Roboto'`,
    },
    body2: {
      fontFamily: `'Roboto'`,
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        container: {
          backdropFilter: 'blur(5px)',
        },
      },
    },
  },
})
